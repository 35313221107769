import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Table from '../components/Table'

export default function VerificationTestProcedureTemplate({
  data,
  pageContext,
}) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath} tableOfContents="true">
      <div className="content">
        Testing Location: {frontmatter.testLocation}
        <hr />
        Date: {frontmatter.date}
        <hr />
        Controlled, Restricted and Confidential Document
        <hr />
      </div>
      <h2 className="title is-2 has-text-info-dark">Overview</h2>
      <p className="ml-5">
        <p>{frontmatter.overviewParagraph1}</p>
        <br />
        <p>{frontmatter.overviewParagraph2}</p>
        <br />
        <p className="ml-5">{frontmatter.overviewParagraph3}</p>
      </p>
      <br />
      <h2 className="title is-2 has-text-info-dark">Lot Selection</h2>
      <p>
        The samples selected for testing were picked from the following lots:
      </p>
      <br />
      <Table table={frontmatter.lotSelectionTable} />
      <br />
      <h3 className="title is-2 has-text-info-dark">Test Equipment</h3>
      <Table table={frontmatter.testEquipmentTable} />
      <br />
      <h2 className="title is-2 has-text-info-dark">
        Test #1 - Device Attachment
      </h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test1Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test1Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials</h3>
        <Table table={frontmatter.test1RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test1Procedure1}</li>
            <li>{frontmatter.test1Procedure2}</li>
            <li>{frontmatter.test1Procedure3}</li>
            <li>{frontmatter.test1Procedure4}</li>
            <li>{frontmatter.test1Procedure5}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test1ResultsTable} />
        </p>
        <br />
        imgsrc
        <br />
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">Test #2 - Device Length</h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test2Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test2Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test2RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test2Procedure1}</li>
            <li>{frontmatter.test2Procedure2}</li>
            <li>{frontmatter.test2Procedure3}</li>
            imgsrc
            <li>{frontmatter.test2Procedure4}</li>
            <li>{frontmatter.test2Procedure5}</li>
            <li>{frontmatter.test2Procedure6}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test2ResultsTable} />
        </p>
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <Table table={frontmatter.test2ConclusionTable} />
        <br />
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">
        Test #3 - Cleaning Procedure
      </h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test3Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test3Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test3RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test3Procedure1}</li>
            <li>{frontmatter.test3Procedure2}</li>
            <li>{frontmatter.test3Procedure3}</li>
            imgsrc
            <li>{frontmatter.test3Procedure4}</li>
            <li>{frontmatter.test3Procedure5}</li>
            <li>{frontmatter.test3Procedure6}</li>
            <li>{frontmatter.test3Procedure7}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test3ResultsTable} />
        </p>
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">Test #4 - Pull Test</h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test4Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test4Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test4RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test4Procedure1}</li>
            <li>{frontmatter.test4Procedure2}</li>
            <li>{frontmatter.test4Procedure3}</li>
            imgsrc
            <li>{frontmatter.test4Procedure4}</li>
            <li>{frontmatter.test4Procedure5}</li>
            <li>{frontmatter.test4Procedure6}</li>
            <li>{frontmatter.test4Procedure7}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test4ResultsTable} />
        </p>
        <br />
        imgsrc
        <br />
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">Test #5 - Seal Strength</h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test5Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test5Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test5RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test5Procedure1}</li>
            <li>{frontmatter.test5Procedure2}</li>
            imgsrc
            <li>{frontmatter.test5Procedure3}</li>
            <li>{frontmatter.test5Procedure4}</li>
            <li>{frontmatter.test5Procedure5}</li>
            <li>{frontmatter.test5Procedure6}</li>
            <li>{frontmatter.test5Procedure7}</li>
            <li>{frontmatter.test5Procedure8}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test5ResultsTable} />
        </p>
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <Table table={frontmatter.test5ConclusionTable} />
        <br />
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">Test #6 - Tear Force</h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test6Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test6Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test6RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test6Procedure1}</li>
            <li>{frontmatter.test6Procedure2}</li>
            <li>{frontmatter.test6Procedure3}</li>
            <li>{frontmatter.test6Procedure4}</li>
            <li>{frontmatter.test6Procedure5}</li>
            <li>{frontmatter.test6Procedure6}</li>
            <li>{frontmatter.test6Procedure7}</li>
            <li>{frontmatter.test6Procedure8}</li>
            <li>{frontmatter.test6Procedure9}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test6ResultsTable} />
        </p>
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <Table table={frontmatter.test6ConclusionTable} />
        <br />
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">Test #7 - Drop Test</h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test7Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test7Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test7RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test7Procedure1}</li>
            <li>{frontmatter.test7Procedure2}</li>
            <li>{frontmatter.test7Procedure3}</li>
            <li>{frontmatter.test7Procedure4}</li>
            <li>{frontmatter.test7Procedure5}</li>
            <li>{frontmatter.test7Procedure6}</li>
            <li>{frontmatter.test7Procedure7}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test7ResultsTable} />
        </p>
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">
        Test #8 - Edge/Corner Sharpness
      </h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test8Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test8Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test8RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test8Procedure1}</li>
            <li>{frontmatter.test8Procedure2}</li>
            <li>{frontmatter.test8Procedure3}</li>
            <li>{frontmatter.test8Procedure4}</li>
            <li>{frontmatter.test8Procedure5}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test8ResultsTable} />
        </p>
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">
        Test #9 - Below Sterile Field Check
      </h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test9Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test9Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test9RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test9Procedure1}</li>
            <li>{frontmatter.test9Procedure2}</li>
            <li>{frontmatter.test9Procedure3}</li>
            <li>{frontmatter.test9Procedure4}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test9ResultsTable} />
        </p>
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">
        Test #10 - Device Weight
      </h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test10Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test10Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test10RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test10Procedure1}</li>
            <li>{frontmatter.test10Procedure2}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test10ResultsTable} />
        </p>
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <Table table={frontmatter.test10ConclusionTable} />
        <br />
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
      <h2 className="title is-2 has-text-info-dark">
        Test #11 - Device Overlap
      </h2>
      <p className="ml-5">
        <h3 className="title is-5">Requirement(s):</h3>
        <p className="ml-5">{frontmatter.test11Requirements}</p>
        <br />
        <h3 className="title is-5">Sampling:</h3>
        {frontmatter.test11Sampling}
        <br />
        <br />
        <h3 className="title is-5">Required Materials:</h3>
        <Table table={frontmatter.test11RequiredMaterialsTable} />
        <h3 className="title is-5">Procedure:</h3>
        <div className="content">
          <ol type="1">
            <li>{frontmatter.test11Procedure1}</li>
            <li>{frontmatter.test11Procedure2}</li>
            <li>{frontmatter.test11Procedure3}</li>
            <li>{frontmatter.test11Procedure4}</li>
            <li>{frontmatter.test11Procedure5}</li>
            <li>{frontmatter.test11Procedure6}</li>
          </ol>
        </div>
        <p className="ml-5">
          <h3 className="title is-5">Results:</h3>
          <Table table={frontmatter.test11ResultsTable} />
        </p>
        <br />
        <h3 className="title is-5">Conclusion:</h3>
        <Table table={frontmatter.test11ConclusionTable} />
        <br />
        <h3 className="title is-5">Testing performed by:</h3>
        <h3 className="title is-5">Tester Qualification:</h3>
        <div className="columns">
          <div className="column">Signature</div>
          <div className="column">Date</div>
        </div>
        <hr />
      </p>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryVerificationTestProcedure($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        author
        productTitle

        date
        testLocation
        overviewParagraph1
        overviewParagraph2
        overviewParagraph3
        lotSelectionTable {
          partNumber
          lotNumber
          assembledLocation
          dateAssembledAndShipped
        }
        testEquipmentTable {
          partNumber
          description
          calDate
          image
        }
        test1Requirements
        test1Sampling
        test1RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test1Procedure1
        test1Procedure2
        test1Procedure3
        test1Procedure4
        test1Procedure5
        test1ResultsTable {
          sample
          deviceLotOrSerialNumber
          abilityToAttachOrRemoveLocationOne
          abilityToAttachOrRemoveLocationTwo
          abilityToAttachOrRemoveLocationThree
          PassOrFail
        }
        test2Requirements
        test2Sampling
        test2RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test2Procedure1
        test2Procedure2
        test2Procedure3
        test2Procedure4
        test2Procedure5
        test2Procedure6
        test2ResultsTable {
          sample
          deviceLotOrSerialNumber
          differenceInLengthInCM
          passOrFail
        }
        test2ConclusionTable {
          partNumber
          mean
          standardDeviation
          specification
          sigmaFromMeanToSpecification
        }
        test3Requirements
        test3Sampling
        test3RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test3Procedure1
        test3Procedure2
        test3Procedure3
        test3Procedure4
        test3Procedure5
        test3Procedure6
        test3Procedure7
        test3ResultsTable {
          sample
          deviceLotOrSerialNumber
          materialDegradation
          passOrFail
        }
        test4Requirements
        test4Sampling
        test4RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test4Procedure1
        test4Procedure2
        test4Procedure3
        test4Procedure4
        test4Procedure5
        test4Procedure6
        test4Procedure7
        test4ResultsTable {
          sample
          deviceLotOrSerialNumber
          barAttachment
          sideAttachment
          passOrFail
        }
        test5Requirements
        test5Sampling
        test5RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test5Procedure1
        test5Procedure2
        test5Procedure3
        test5Procedure4
        test5Procedure5
        test5Procedure6
        test5Procedure7
        test5Procedure8
        test5ResultsTable {
          sample
          deviceLotOrSerialNumber
          sealForceInIbf
          passOrFail
        }
        test5ConclusionTable {
          partNumber
          mean
          standardDeviation
          specification
          sigmaFromMeanToSpecification
        }
        test6Requirements
        test6Sampling
        test6RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test6Procedure1
        test6Procedure2
        test6Procedure3
        test6Procedure4
        test6Procedure5
        test6Procedure6
        test6Procedure7
        test6Procedure8
        test6Procedure9
        test6ResultsTable {
          sample
          deviceLotOrSerialNumber
          tearForceInIbf
          passOrFail
        }
        test6ConclusionTable {
          partNumber
          mean
          standardDeviation
          specification
          sigmaFromMeanToSpecification
        }
        test7Requirements
        test7Sampling
        test7RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test7Procedure1
        test7Procedure2
        test7Procedure3
        test7Procedure4
        test7Procedure5
        test7Procedure6
        test7Procedure7
        test7ResultsTable {
          sample
          deviceLotOrSerialNumber
          orientation1
          orientation2
          orientation3
          passOrFail
        }
        test8Requirements
        test8Sampling
        test8RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test8Procedure1
        test8Procedure2
        test8Procedure3
        test8Procedure4
        test8Procedure5
        test8ResultsTable {
          sample
          deviceLotOrSerialNumber
          tearsInGlove
          passOrFail
        }
        test9Requirements
        test9Sampling
        test9RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test9Procedure1
        test9Procedure2
        test9Procedure3
        test9Procedure4
        test9ResultsTable {
          sample
          deviceLotOrSerialNumber
          belowSterileField
          passOrFail
        }
        test10Requirements
        test10Sampling
        test10RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test10Procedure1
        test10Procedure2
        test10ResultsTable {
          sample
          deviceLotOrSerialNumber
          deviceWeight
          passOrFail
        }
        test10ConclusionTable {
          partNumber
          mean
          standardDeviation
          specification
          sigmaFromMeanToSpecification
        }
        test11Requirements
        test11Sampling
        test11RequiredMaterialsTable {
          partNumber
          description
          quantity
          image
        }
        test11Procedure1
        test11Procedure2
        test11Procedure3
        test11Procedure4
        test11Procedure5
        test11Procedure6
        test11ResultsTable {
          sample
          deviceLotOrSerialNumber
          leftOverlap
          rightOverlap
          passOrFail
        }
        test11ConclusionTable {
          partNumber
          location
          mean
          standardDeviation
          specification
          sigmaFromMeanToSpecification
        }
      }
    }
  }
`
